<template>
  <div class="otp-form relative h-auto min-h-[200px]">
    <transition
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
      enter-active-class="transition duration-300"
      leave-active-class="transition duration-300">
      <div class="absolute top-0 left-0 w-full z-10 h-full bg-white/30 dark:bg-zinc-900/30 dark:text-white backdrop-blur-sm flex items-center justify-center" v-if="isFirstLoading">
        <div class="text-center">
          <p class="w-48 font-medium">
            enviando código para
            seu email e celular
          </p>
          <div class="flex items-center mt-8">
            <Loader class="mx-auto" />
          </div>
          <!-- aguarde alguns instantes -->
        </div>
      </div>
    </transition>
    <form class="otp" ref="otpForm" @submit.stop.prevent="onSubmitOtp" name="otp">
      <div class="flex items-start justify-center">
        <!-- <img src="/cellphone-notification.png" class="dark:invert mr-4 w-20" /> -->
        <img src="/mobi-notification.png" class="light:invert opacity-60 mr-4 w-14" />
        <div class="">
          <p class="font-medium text-md mb-2">
            Enviamos um código por email <small class="text-xs opacity-60">e sms</small>
          </p>
          <!-- <p class="text-md mb-4">
            para continuar, informe abaixo
          </p> -->

          <div class="otp-field mb-4">
            <label class="form-label mb-2">Informe o código recebido</label>
            <input autofocus class="form-input w-48 text-lg font-medium text-center" type="text" pattern="\d{6}" id="otp-code" v-model="otpCode" minlength="6" maxlength="6" required inputmode="numeric" autocomplete="one-time-code" />
            <div class="otp-mask"></div>
          </div>
          <div class="otp py-2">
            <ButtonSubmit class="w-48" :disabled="!isFormOtpValid || otpCode == '' || otpCode == lastOtpCode" :loading="isFormOtpLoading" text="Continuar" />
          </div>
        </div>
      </div>

      <!-- não recebeu? -->
      <div class="mt-6 flex justify-center text-sm">
        <button :disabled="isResendDisabled" class="flex opacity-70 hover:opacity-100 items-center p-2 hover:border rounded disabled:opacity-60" @click.stop.prevent="resendOtp">
          <span>reenviar código</span>
          <icon-send class="ml-2" />
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
// import Loader from './Loader.vue'
import ButtonSubmit from '@/components/ButtonSubmit.vue'
import Loader from '@/components/Loader.vue'
import UserService from '@/services/user.service.js'

import { useUserStore } from '@/store/user.js'
import { storeToRefs } from 'pinia'


const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

const isWaitingOtp = ref(false)
const isEmailSent = ref(false)
const isFormOtpLoading = ref(false)
const isFormOtpValid = ref(false)
const isResendDisabled = ref(true)
const otpForm = ref('')
const otpCode = ref('')
const lastOtpCode = ref('')
const resendInterval = ref(null)
const isFirstLoading = ref(true)
const loadingTimeout = ref(null)

const { currentUser, currentProducer, isProducer, loggedIn: isLoggedIn } = storeToRefs(userStore)


const toast = useNuxtApp().$toast

const props = defineProps({
  email: {
    required: true,
    type: String,
    default: ''
  },
})
const { $posthog, $ahoy } = useNuxtApp()

const emit = defineEmits(['onOtpLogin', 'onOtpSuccess'])

const validateOtpForm = () => {
  try {
    isFormOtpValid.value = otpForm.value.checkValidity() && otpCode.value.toString().length === 6
  } catch (_) {
    isFormOtpValid.value = true // pra nao dar merda
  }
}

const onSubmitOtp = async () => {
  try {
    isFormOtpLoading.value = true
    const response = await UserService.loginOtp(props.email, otpCode.value)

    await userStore.login(response)

    // useNuxtApp
    if($posthog) {
      const posthog = $posthog()
      posthog.identify(
        currentUser.value.id, {
          email: currentUser.value.email,
          cellphone: currentUser.value.cellphone,
          userKind: isProducer.value.slug ? 'producer' : 'customer',
          producer: isProducer.value.slug ? currentProducer.value.slug : null
        }
      )
    }

    if($ahoy) {
      const ahoy = $ahoy()
      ahoy.configure({ headers: { "Authorization": response.headers.authorization }})
    }

    emit('onOtpSuccess', response.data)
    // onSubmit()
    isFormOtpLoading.value = false
  } catch (err) {
    console.log(err)
    if (err.request && err.request.status && err.request.status === 401) {
      toast('código inválido', { type: 'error' })

      const clonedOtpCode = otpCode.value
      lastOtpCode.value = clonedOtpCode

      isResendDisabled.value = false
      isFormOtpLoading.value = false
      return
    }

    isFormOtpLoading.value = false
    if (err.response && err.response.data) {
      toast(JSON.stringify(err.response.data), { type: 'error' })
    }
  }
}

const resendOtp = async () => {
  // onSubmit()

  await UserService.login({ email: props.email })

  toast('reenviamos o código', { type: 'info' })
  resetResend()
}

const resetResend = () => {
  if (resendInterval.value) {
    clearInterval(resendInterval.value)
  }

  isResendDisabled.value = true
  resendInterval.value = setInterval(() => {
    isResendDisabled.value = false
  }, 15 * 1000)

  isFirstLoading.value = true

  if (loadingTimeout.value) {
    clearTimeout(loadingTimeout.value)
  }

  loadingTimeout.value = setTimeout(() => {
    isFirstLoading.value = false
  }, 5000)
  // }, 25000)
}

onMounted(() => {
  resetResend()
})

watch(otpCode, (newVal) => {
  otpCode.value = newVal.replace(/\D/g, '')
  validateOtpForm()
  if (otpCode.value.toString().length === 6) {
    onSubmitOtp()
  }
})


// emit('onOtpSuccess', response.data)
// // onSubmit()
// isFormOtpLoading.value = false
</script>
